<template lang="">
  <main>
    <section class="contato py-5">
      <div class="container">
        <h3 class="text-center mb-4 mb-lg-4">Contato</h3>
        <p class="lead text-center col-md-8 m-auto">
          Para acessar nossa plataforma você precisa ter instalado o app do
          Telegram. Nós usamos eles pois criamos um robôzinho que auxilia o
          gerenciamento das grades de atividades. Se você já tem ele instalado,
          basta acessar a plataforma.
        </p>
        <div class="row justify-content-center">
          <div class="col-auto">
            <a
              href="https://play.google.com/store/apps/details?id=org.telegram.messenger&hl=pt_BR"
              class="btn btn-outline-light mt-4"
              target="_blank"
              >Android</a
            >
          </div>
          <div class="col-auto">
            <a
              href="https://apps.apple.com/br/app/telegram-messenger/id686449807"
              class="btn btn-outline-light mt-4"
              target="_blank"
              >Iphone</a
            >
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: "Contato",
};
</script>
<style lang="scss">
.contato {
  display: flex;
  align-items: center;
  height: 100%;
  color: white;
  background: #214b69;
  h3 {
    &:after {
      content: "";
      display: block;
      height: 2px;
      width: 70px;
      margin: auto;
      margin-top: 15px;
      background: white !important;
    }
  }
  .btn {
    margin: auto;
    display: block;
    width: fit-content;
  }
}
</style>
