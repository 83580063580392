<template>
  <section class="cla py-5">
    <div class="container">
      <h3 class="text-center mb-4 mb-lg-4">Faça parte também do nosso clã</h3>
      <p class="lead text-center col-md-8 m-auto">
        Para fazer parte, você precisa se registrar no grupo primeiro e depois
        solicitar que um dos administradores o aprove.
      </p>
      <p class="text-center mb-0" v-if="!api">Carregando informação...</p>
      <div class="row" v-else>
        <div
          class="col-lg-4 col-md-6 mb-4"
          v-for="cla in api.clans"
          :key="cla.id"
        >
          <a
            :href="`https://www.bungie.net/pt-br/ClanV2/?groupid=${cla.id}`"
            :class="cla.network"
            target="_blank"
            @click="aviso"
          >
            <div class="wrapper">
              <div class="d-flex logo_name">
                <img
                  src="../assets/img/logoxbox.png"
                  :alt="cla.network"
                  v-if="cla.network === 'xb'"
                />
                <img
                  src="../assets/img/logoSony.png"
                  :alt="cla.network"
                  v-if="cla.network === 'ps'"
                />
                <img
                  src="../assets/img/logopc.png"
                  :alt="cla.network"
                  v-if="cla.network === 'pc'"
                />
                <img
                  src="../assets/img/CP_destiny.png"
                  :alt="cla.network"
                  v-if="cla.network === 'mp'"
                />
                <h5>{{ cla.callsign }}</h5>
              </div>
              <p v-html="cla.name" class="name"></p>
              <span class="d-block">{{ cla.members }} membro(s) </span>

              <p class="btn_enter">Solicitar</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "VueCla",
  props: {
    api: {
      type: Object,
      default: null,
    },
  },
  methods: {
    aviso() {
      alert(
        "Lembrando: para fazer parte do clã selecionado, você precisa se registrar no grupo principal primeiro, no Telegram, e depois solicitar que um dos administradores o aprove."
      );
    },
  },
};
</script>
<style lang="scss">
.cla {
  color: white;
  background: #212529;
  .img {
    width: 100px;
    text-align: center;
    display: block;
    margin: auto;
  }
  h3 {
    &:after {
      content: "";
      display: block;
      height: 2px;
      width: 70px;
      margin: auto;
      margin-top: 15px;
      background: white !important;
    }
  }

  .lead {
    margin-bottom: 30px !important;
  }

  a {
    text-decoration: none !important;

    .wrapper {
      overflow: hidden;
      text-align: center;
      color: white;
      padding: 1rem;
      position: relative;
      min-height: 160px;
      border: 2px solid;
      border-radius: 5px;

      .logo_name {
        align-items: center;
        img {
          width: 40px;
          text-align: center;
          display: block;
        }
        h5 {
          margin-left: 10px;
          margin-bottom: 0px;
        }
      }

      .name {
        margin-top: 20px;
        font-size: 1.3rem;
        margin-bottom: 30px;
      }

      .btn_enter {
        padding-top: 10px;
        display: block;
        border-top: 1px solid white;
        margin-bottom: 0px;
      }

      span {
        position: absolute;
        top: 0;
        right: 0;
        background: #00000073;
        color: white;
        padding: 5px 10px;
        border-radius: 0 0 0 10px;
      }

      &:hover {
        transform: scale(1.03);
      }
    }

    &.ps {
      .wrapper {
        border-color: #75c4ff;
        background: rgb(11, 135, 228);
        background: linear-gradient(
          25deg,
          rgba(11, 135, 228, 1) 0%,
          rgba(11, 135, 228, 1) 2%,
          rgba(11, 44, 133, 1) 50%,
          rgba(11, 135, 228, 1) 98%,
          rgba(11, 135, 228, 1) 100%
        );
      }
    }
    &.xb {
      .wrapper {
        background: rgb(0, 129, 1);
        border-color: #379c37;
        background: linear-gradient(
          25deg,
          rgba(0, 129, 1, 1) 0%,
          rgba(0, 129, 1, 1) 2%,
          rgba(0, 80, 0, 1) 50%,
          rgba(0, 129, 1, 1) 98%,
          rgba(0, 129, 1, 1) 100%
        );
      }
    }
    &.pc {
      .wrapper {
        background: rgb(63, 1, 105);
        border-color: #583271;
        background: linear-gradient(
          25deg,
          rgba(63, 1, 105, 1) 0%,
          rgba(63, 1, 105, 1) 2%,
          rgba(35, 1, 57, 1) 50%,
          rgba(63, 1, 105, 1) 98%,
          rgba(63, 1, 105, 1) 100%
        );
      }
    }
    &.mp {
      .wrapper {
        background: rgb(11, 135, 228);
        border-color: #f6f6f6;
        background: linear-gradient(
          25deg,
          rgba(11, 135, 228, 1) 0%,
          rgba(11, 135, 228, 1) 2%,
          rgba(63, 1, 105, 1) 50%,
          rgba(0, 129, 1, 1) 98%,
          rgba(0, 129, 1, 1) 100%
        );
        color: white;
      }
    }
  }
}
</style>
