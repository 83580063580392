<template>
  <section class="video container-fluid">
    <div class="overlay"></div>
    <video
      playsinline="playsinline"
      autoplay="autoplay"
      muted="muted"
      loop="loop"
    >
      <source
        src="https://www.bungie.net/pubassets/pkgs/152/152526/d2_witch_queen_reveal_homepage_hero_bg.mp4"
        type="video/mp4"
      />
    </video>
    <div class="container h-100 py-5">
      <div class="d-flex h-100 text-center align-items-end">
        <div class="w-100 text-white">
          <div class="intro">
            <h2 class="display-4">A BRUXA-RAINHA</h2>
            <p class="lead mb-4">
              Eu aguardo você. Venha ao meu Mundo-trono e veja a verdade.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "VueVideo",
};
</script>
<style lang="scss">
.video {
  position: relative;
  background-color: black;
  height: 80vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

.video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.video .container {
  position: relative;
  z-index: 2;
}

.video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.2;
  z-index: 1;
}

.intro {
  &::before,
  &::after {
    content: "";
    display: block;
    background: #006d17;
    height: 2px;
    width: 300px;
    margin: 20px auto 5px auto;
  }
}
@media (max-width: 600px) {
  .video {
    background: url("https://www.bungie.net/pubassets/pkgs/152/152526/d2_witch_queen_reveal_homepage_hero_bg_mobile.jpg")
      black no-repeat top center scroll !important;
  }

  .video video {
    display: none !important;
  }
}
</style>
