<template>
  <section class="join py-5" id="join">
    <div class="container">
      <img src="../assets/img/CP_destiny.png" alt="Cross-Play" class="img" />
      <h3 class="text-center mb-4 mb-lg-4">Junte-se a nós</h3>
      <p class="lead text-center col-md-8 m-auto">
        Com a chegada do cross-play os jogadores de todas as plataformas serão
        capazes de jogar as atividades de Destiny 2. Então unificamos todo
        sistema.
      </p>
      <a
        href="https://t.me/GrupoIncursores"
        class="btn btn-outline-light mt-4"
        target="_blank"
        >Clique Aqui</a
      >
    </div>
  </section>
</template>
<script>
export default {
  name: "VueJoin",
};
</script>
<style lang="scss">
.join {
  color: white;
  background: -moz-linear-gradient(left, #0b87e4 0%, #3f0169 45%, #008101 100%);
  background: -webkit-linear-gradient(
    left,
    #0b87e4 0%,
    #3f0169 45%,
    #008101 100%
  );
  background: linear-gradient(to right, #0b87e4 0%, #3f0169 45%, #008101 100%);
  .img {
    width: 100px;
    text-align: center;
    display: block;
    margin: auto;
  }
  h3 {
    &:after {
      content: "";
      display: block;
      height: 2px;
      width: 70px;
      margin: auto;
      margin-top: 15px;
      background: white !important;
    }
  }
  .btn {
    margin: auto;
    display: block;
    width: fit-content;
  }
}
</style>
