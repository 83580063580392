<template>
  <main>
    <VueVideo />
    <VueInfoBar :api="api" />
    <VueJoin />
    <VueCla :api="api" />
    <VueCooperate />
    <div class="shortcut">
      <a href="https://t.me/GrupoIncursores" class="link" target="_blank">
        <i class="bi bi-headset"></i>
        <span>Juntar</span>
      </a>
      <a href="#cooperate" class="link">
        <i class="bi bi-currency-dollar"></i>
        <span>Colaborar</span>
      </a>
    </div>
  </main>
</template>
<script>
import VueVideo from "@/components/VueVideo.vue";
import VueInfoBar from "@/components/VueInfoBar.vue";
import VueJoin from "@/components/VueJoin.vue";
import VueCooperate from "@/components/VueCooperate.vue";
import VueCla from "@/components/VueCla.vue";

export default {
  name: "Home",
  data() {
    return {
      api: null,
    };
  },
  components: {
    VueVideo,
    VueInfoBar,
    VueJoin,
    VueCla,
    VueCooperate,
  },
  methods: {
    loadApi() {
      fetch("https://nessus.incursores.com.br/api/v1/bot/stats/44")
        .then(async (data) => data.json())
        .then(async (res) => (this.api = res));
    },
  },
  async created() {
    this.loadApi();
  },
};
</script>
<style lang="scss">
.shortcut {
  position: fixed;
  background: #00000090;
  right: 0;
  height: auto;
  width: auto;
  top: 45%;
  z-index: 300;
  padding: 10px;
  border-radius: 10px 0px 0px 10px;

  .link {
    color: gray;
    text-decoration: none;
    text-align: center;
    display: block !important;

    i {
      font-size: 1.5rem;
      color: gray;
    }

    span {
      display: block;
    }
    &:first-child {
      border-bottom: 1px solid #b3b3b3;
      padding-bottom: 10px;
      margin-bottom: 5px;
    }

    &:hover {
      color: white;
      i {
        color: white;
      }
    }
  }
}
</style>
