<template>
  <header class="header navbar-dark sticky-top py-2">
    <nav class="navbar navbar-expand-md container">
      <a class="navbar-brand" href="/"
        ><img src="../assets/img/logoN.png" alt="inc" class="logo"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar"
        aria-controls="navbar"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbar">
        <ul class="navbar-nav ml-auto text-center mt-4 mt-md-0">
          <li class="nav-item">
            <router-link :to="{ name: 'Home' }" class="nav-link"
              >Home</router-link
            >
          </li>
          <li class="nav-item">
            <router-link :to="{ name: 'Contato' }" class="nav-link"
              >Contato</router-link
            >
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  name: "VueHeader",
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.header {
  background: #212529;
  .logo {
    width: 210px;
  }

  .router-link-exact-active {
    color: white !important;
  }
}
</style>
