<template>
  <section class="cooperate py-5" id="cooperate">
    <div class="container">
      <h3 class="text-center mb-4 mb-lg-4">Colabore</h3>
      <div class="lead text-center col-md-8 m-auto">
        Se você quiser colaborar financeiramente com a plataforma, faça um
        <strong>PIX</strong> para o <strong>CPF 139.467.037-04</strong>.
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss">
.cooperate {
  background: #214b69;
  color: white;
  h3 {
    &:after {
      content: "";
      display: block;
      height: 2px;
      width: 70px;
      margin: auto;
      margin-top: 15px;
      background: white !important;
    }
  }
  .btn {
    margin: auto;
    display: block;
    width: fit-content;
  }
}
</style>
